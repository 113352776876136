import { ChainId, JSBI, Percent, Token } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import {
  arbitrumOneTokens, baseMainnetTokens,
  heifLayerTokens,
  opMainnetTokens,
  opSepoliaTokens,
  polygonMainnetTokens,
  sepoliaTokens
} from './tokens'
import { ChainTokenList } from './types'

export const ROUTER_ADDRESS = {
  [ChainId.OP_MAINNET]: '0x55E5089454e6eC1002c1Bff79D00de3Ff1244491',
  [ChainId.HEIF_LAYER]: '0xe9BE8fAc07B8A5167956C9fC7829d775cDa95226',
  [ChainId.ARBITRUM_ONE]: '0x3565C4f2b53EF9635d2AbeC27Cac4FD625281DDe',
  [ChainId.POLYGON]: '0x55E5089454e6eC1002c1Bff79D00de3Ff1244491',
  [ChainId.OP_SEPOLIA]: '0xe68dBf5D9d9d64780434edD7DaBd5E5244C8581E',
  [ChainId.BASE]: '0xDd7D4e332FD047682bB427722F00C4bA6d5e2188',
  [ChainId.SEPOLIA]: '0xBE8D34848b2231Cc8eb33569dF900C0f12c8CdEb',
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.OP_MAINNET]: [opMainnetTokens.weth, opMainnetTokens.ejoe, opMainnetTokens.usdt],
  [ChainId.OP_SEPOLIA]: [opSepoliaTokens.weth, opSepoliaTokens.ejoe, opSepoliaTokens.usdt],
  [ChainId.SEPOLIA]: [sepoliaTokens.weth, sepoliaTokens.ejoe, sepoliaTokens.usdt],
  [ChainId.HEIF_LAYER]: [sepoliaTokens.weth, sepoliaTokens.ejoe, sepoliaTokens.usdt],
  [ChainId.POLYGON]: [polygonMainnetTokens.weth, polygonMainnetTokens.ejoe, polygonMainnetTokens.usdt],
  [ChainId.BASE]: [baseMainnetTokens.weth, baseMainnetTokens.ejoe, baseMainnetTokens.usdt],
  [ChainId.ARBITRUM_ONE]: [arbitrumOneTokens.weth, arbitrumOneTokens.ejoe, arbitrumOneTokens.usdt],
}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.OP_MAINNET]: {},
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.OP_MAINNET]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.OP_MAINNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.OP_MAINNET]: [opMainnetTokens.weth, opMainnetTokens.ejoe, opMainnetTokens.usdt, opMainnetTokens.usdc],
  [ChainId.POLYGON]: [polygonMainnetTokens.weth, polygonMainnetTokens.ejoe, polygonMainnetTokens.usdt, polygonMainnetTokens.usdc],
  [ChainId.ARBITRUM_ONE]: [arbitrumOneTokens.weth, arbitrumOneTokens.ejoe, arbitrumOneTokens.usdt, arbitrumOneTokens.usdc],
  [ChainId.OP_SEPOLIA]: [opSepoliaTokens.weth, opSepoliaTokens.ejoe, opSepoliaTokens.usdt,opSepoliaTokens.usdc],
  [ChainId.BASE]: [baseMainnetTokens.weth, baseMainnetTokens.ejoe, baseMainnetTokens.usdt,baseMainnetTokens.usdc],
  [ChainId.SEPOLIA]: [sepoliaTokens.weth, sepoliaTokens.ejoe, sepoliaTokens.usdt,sepoliaTokens.usdc],
  [ChainId.HEIF_LAYER]: [heifLayerTokens.weth, heifLayerTokens.ejoe, heifLayerTokens.usdt,heifLayerTokens.usdc],
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(10)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much WETH so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)) // .01 WETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE = new Percent(JSBI.BigInt(25), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// WETH
export const DEFAULT_INPUT_CURRENCY = 'WETH'
// EJOE
export const DEFAULT_OUTPUT_CURRENCY = '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'

// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const LIMIT_ORDERS_DOCS_URL = 'https://docs.pancakeswap.finance/products/pancakeswap-exchange/limit-orders'
